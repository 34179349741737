import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { ENVIRONMENT } from '@instaclause-webapp/shared-core';
import { authInterceptor } from '@instaclause-webapp/shared-core';
import { provideTransloco } from '@jsverse/transloco';
import {
  TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule,
} from 'ngx-timeago';

import { environment } from '../environments/environment';

import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(
      withInterceptors([ authInterceptor ]),
    ),
    provideTransloco({
      config: {
        availableLangs: [ 'en', 'nl', 'fr' ],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    importProvidersFrom(
      TimeagoModule.forRoot({
        intl: {
          provide: TimeagoIntl,
        },
        formatter: {
          provide: TimeagoFormatter,
          useClass: TimeagoCustomFormatter,
        },
      }),
    ),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
  ],
};
